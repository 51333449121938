.scanned__base {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
}

.scanned__title {
  #{&}__text {
    text-align: center;
    color: white;
    font-weight: 300;
    font-size: 2rem;
  }
}

.scanned__image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../Images/Success-Screen.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 8%;
  padding-bottom: 8%;
  border: solid rgba(255, 255, 255, 0.23) 1px;

  #{&}__logo {
    padding: 30px;
  }
}

.scanned__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 1166px;
  height: 100px;
}

@media (max-width: 1023px) {
  .scanned__title {
    #{&}__text {
      font-size: 1.8rem;
    }
  }
}
@media (max-width: 767px) {
  .scanned__title {
    #{&}__text {
      font-size: 1.2rem;
    }
  }
  .scanned__image {
    #{&}__logo {
      height: 110px;
    }
  }

  .scanned__footer {
    padding: 15px;

    #{&}__logo {
      height: 30px;
    }
  }
}
